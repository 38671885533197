/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.bg-unsplash{
	background: url('../images/backgrounds/bg-cms.png');
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media screen and (max-width: 991px) {
	.bg-mobile{
		background: url('../images/backgrounds/bg-cms.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.bg-mobile::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: white; /* Overlay color */
		opacity: 0.8; /* Adjust the opacity for transparency */
	  }
}