/*=========================================================================================
	File Name: customizer.scss
	Description: CSS used for demo purpose only. Remove this css from your project.
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

.sun-editor * {
    font-size: 1rem !important;
    font-family: $font-family-sans-serif;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
    margin: 5.75rem auto !important;
    // background-color: #fff;
    // -webkit-background-clip: padding-box;
    // background-clip: padding-box;
    // border: 1px solid rgba(0,0,0,.2);
    // border-radius: 4px;
    // box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
    // z-index: 9999;
}